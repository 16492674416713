var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[(_vm.loading)?_c('v-row',[_c('v-col',[_c('h3',{staticClass:"text-center"},[_c('v-progress-circular',{staticClass:"ml-3",attrs:{"indeterminate":"","color":"primary"}}),_vm._v(" Getting data . . . ")],1)])],1):_vm._e(),_c('v-alert',{directives:[{name:"show",rawName:"v-show",value:(_vm.error),expression:"error"}],staticStyle:{"z-index":"2","position":"fixed","right":"15px","min-width":"300px"},attrs:{"transition":"fade-transition","color":"error","type":"error"}},[_vm._v(" "+_vm._s(_vm.errorMessage)+" ")]),(!_vm.loading)?_c('v-row',{attrs:{"justify":"center","id":"to-be-print"}},[_c('v-col',{attrs:{"xs":"12","xl":"6"}},[_c('v-row',[_c('v-col',[_c('p',{staticClass:"text-h4"},[_vm._v("Personal KYC Form")])])],1),_c('v-row',[_c('v-col',_vm._l((_vm.formData.frm_sects),function(section){return _c('v-card',{key:section.id,attrs:{"flat":""}},[_c('v-card-title',[_vm._v(" "+_vm._s(section.title)+" ")]),_c('v-card-text',_vm._l((section.frm_rsp_qs),function(rsp_q){return _c('div',{key:rsp_q.id},[_c('v-divider'),_c('v-row',{staticClass:"py-2"},[_c('v-col',{staticClass:"grey--text",attrs:{"cols":"9"}},[(rsp_q.response ? true : false)?_c('span',[_vm._v(" • ")]):_vm._e(),_vm._v(" "+_vm._s(rsp_q.frm_q.title)+" ")]),_c('v-col',{staticClass:"green--text",attrs:{"cols":"3"}},[_vm._v(" "+_vm._s(rsp_q.response)+" ")])],1),_vm._l((rsp_q.type ===
                  'multiple choice'
                    ? rsp_q.frm_opts
                    : []),function(option,option_index){return _c('span',{key:option.id},[_c('v-divider'),_c('v-row',{staticClass:"py-2"},[_c('v-col',{staticClass:"text-right",attrs:{"cols":"2"}},[_vm._v(" "+_vm._s(option_index === 0 ? "Selected: " : "")+" ")]),_c('v-col',{staticClass:"green--text",attrs:{"cols":"10"}},[_vm._v(" "+_vm._s(option.choice)+" ")])],1)],1)}),_vm._l((rsp_q.accounts),function(account,account_index){return _c('span',{key:account.id},[_c('v-divider'),_c('v-row',{staticClass:"py-2"},[_c('v-col',{staticClass:"grey--text text-right",attrs:{"cols":"2"}},[_vm._v(" "+_vm._s(account_index === 0 ? "Account List: " : "")+" ")]),_c('v-col',{staticClass:"green--text",attrs:{"cols":"7"}},[_vm._v(" • "+_vm._s(account.name))])],1)],1)}),_vm._l((rsp_q.frm_rsp_qs),function(fq_1,fq_1_index){return _c('span',{key:fq_1.id},[_c('v-divider'),_c('v-row',{staticClass:"py-2"},[_c('v-col',{attrs:{"cols":"1"}}),_c('v-col',{staticClass:"grey--text",attrs:{"cols":"8"}},[_vm._v(" • "+_vm._s(fq_1.frm_q && fq_1.frm_q.title ? fq_1.frm_q.title : "")+" "),(
                          rsp_q.frm_q.title ===
                          'Please provide the details of any accounts through which you will be making investments.'
                        )?_c('span',[_vm._v(" Type of account #"+_vm._s(fq_1_index + 1)+" ")]):_vm._e()]),_c('v-col',{staticClass:"green--text",attrs:{"cols":"3"}},[_vm._v(" "+_vm._s(fq_1.response)+" ")])],1),_vm._l((fq_1.frm_rsp_qs),function(fq_2){return _c('span',{key:fq_2.id},[(fq_1.response !== 'Cash')?_c('v-divider'):_vm._e(),(fq_1.response !== 'Cash')?_c('v-row',{staticClass:"py-2"},[_c('v-col',{attrs:{"cols":"1"}}),_c('v-col',{staticClass:"grey--text pl-10",attrs:{"cols":"8"}},[_vm._v(" • "+_vm._s(fq_2.frm_q && fq_2.frm_q.title ? fq_2.frm_q.title : "")+" "),(
                            rsp_q.frm_q.title ===
                            'Please provide the details of any accounts through which you will be making investments.'
                          )?_c('span',[_vm._v(" Name of Institution ")]):_vm._e()]),_c('v-col',{staticClass:"green--text",attrs:{"cols":"3"}},[_vm._v(" "+_vm._s(fq_2.response)+" ")])],1):_vm._e()],1)})],2)})],2)}),0)],1)}),1)],1),_c('v-row',[_c('v-col',[_c('v-btn',{on:{"click":function($event){return _vm.$router.go(-1)}}},[_vm._v(" Cancel ")]),_c('v-btn',{staticClass:"ml-5",on:{"click":_vm.handle_print_btn}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v(" mdi-printer ")]),_vm._v(" Print ")],1)],1)],1)],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div>
    <Initial_KYC_Response
      :form_response_id="id"
      v-if="form_unique_id === 'initial-kyc-2024-v1'"
    />
    <Personal_KYC_Response
      :form_id="id"
      v-if="form_unique_id === 'personal-kyc-2024-v1'"
    />
    <Corporation_KYC_Response
      :form_id="id"
      v-if="form_unique_id === 'corporation-kyc-2023-v1'"
    />
    <Mortgage_Company_KYC_Response
      :form_id="id"
      v-if="form_unique_id === 'mortgage-company-kyc-2023-v1'"
    />
  </div>
</template>

<script>
import Initial_KYC_Response from "@/components/Investor_KYC/Initial_Response";
import Personal_KYC_Response from "@/components/Investor_KYC/Personal_Response";
import Corporation_KYC_Response from "@/components/Investor_KYC/Corporation_Response";
import Mortgage_Company_KYC_Response from "@/components/Investor_KYC/Mortgage_Company_Response";

export default {
  props: ["id", "form_unique_id"],

  components: {
    Initial_KYC_Response,
    Personal_KYC_Response,
    Corporation_KYC_Response,
    Mortgage_Company_KYC_Response,
  },
};
</script>

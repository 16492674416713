<template>
  <v-container>
    <v-row v-if="loading">
      <v-col>
        <h3 class="text-center">
          <v-progress-circular indeterminate class="ml-3" color="primary" />
          Getting data . . .
        </h3>
      </v-col>
    </v-row>

    <v-alert
      v-show="error"
      transition="fade-transition"
      color="error"
      type="error"
      style="z-index: 2; position: fixed; right: 15px; min-width: 300px"
    >
      {{ errorMessage }}
    </v-alert>

    <v-row v-if="!loading" justify="center" id="to-be-print">
      <v-col xs="12" xl="6">
        <v-row>
          <v-col>
            <p class="text-h4">Personal KYC Form</p>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-card
              v-for="section in formData.frm_sects"
              :key="section.id"
              flat
            >
              <v-card-title>
                {{ section.title }}
              </v-card-title>

              <v-card-text>
                <div v-for="rsp_q in section.frm_rsp_qs" :key="rsp_q.id">
                  <v-divider />
                  <v-row class="py-2">
                    <v-col cols="9" class="grey--text">
                      <span v-if="rsp_q.response ? true : false"> • </span>
                      {{ rsp_q.frm_q.title }}
                    </v-col>
                    <v-col cols="3" class="green--text">
                      {{ rsp_q.response }}
                    </v-col>
                  </v-row>

                  <span
                    v-for="(option, option_index) in rsp_q.type ===
                    'multiple choice'
                      ? rsp_q.frm_opts
                      : []"
                    :key="option.id"
                  >
                    <v-divider />
                    <v-row class="py-2">
                      <v-col cols="2" class="text-right">
                        {{ option_index === 0 ? "Selected: " : "" }}
                      </v-col>
                      <v-col cols="10" class="green--text">
                        {{ option.choice }}
                      </v-col>
                    </v-row>
                  </span>

                  <span
                    v-for="(account, account_index) in rsp_q.accounts"
                    :key="account.id"
                  >
                    <v-divider />
                    <v-row class="py-2">
                      <v-col cols="2" class="grey--text text-right">
                        {{ account_index === 0 ? "Account List: " : "" }}
                      </v-col>
                      <v-col cols="7" class="green--text">
                        • {{ account.name }}</v-col
                      >
                    </v-row>
                  </span>

                  <span
                    v-for="(fq_1, fq_1_index) in rsp_q.frm_rsp_qs"
                    :key="fq_1.id"
                  >
                    <v-divider />
                    <v-row class="py-2">
                      <v-col cols="1"> </v-col>
                      <v-col cols="8" class="grey--text">
                        •
                        {{
                          fq_1.frm_q && fq_1.frm_q.title ? fq_1.frm_q.title : ""
                        }}
                        <span
                          v-if="
                            rsp_q.frm_q.title ===
                            'Please provide the details of any accounts through which you will be making investments.'
                          "
                        >
                          Type of account #{{ fq_1_index + 1 }}
                        </span>
                      </v-col>
                      <v-col cols="3" class="green--text">
                        {{ fq_1.response }}
                      </v-col>
                    </v-row>

                    <span v-for="fq_2 in fq_1.frm_rsp_qs" :key="fq_2.id">
                      <v-divider v-if="fq_1.response !== 'Cash'" />
                      <v-row class="py-2" v-if="fq_1.response !== 'Cash'">
                        <v-col cols="1"> </v-col>
                        <v-col cols="8" class="grey--text pl-10">
                          •
                          {{
                            fq_2.frm_q && fq_2.frm_q.title
                              ? fq_2.frm_q.title
                              : ""
                          }}
                          <span
                            v-if="
                              rsp_q.frm_q.title ===
                              'Please provide the details of any accounts through which you will be making investments.'
                            "
                          >
                            Name of Institution
                          </span>
                        </v-col>
                        <v-col cols="3" class="green--text">
                          {{ fq_2.response }}
                        </v-col>
                      </v-row>
                    </span>
                  </span>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-btn @click="$router.go(-1)"> Cancel </v-btn>
            <v-btn class="ml-5" @click="handle_print_btn">
              <v-icon class="mr-2"> mdi-printer </v-icon> Print
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import API from "@/plugins/API";

export default {
  name: "Personal_KYC_Response",

  props: ["form_id"],

  data: () => ({
    loading: false,
    error: false,
    errorMessage: "",

    formData: {},
  }),

  methods: {
    handle_print_btn() {
      window.print();
    },

    async getFormData() {
      try {
        this.loading = true;
        const found_form_data = await API().get(
          `api/form/read_form_response?id=${this.form_id}`
        );
        console.log("found_form_data: ", found_form_data);

        this.formData = found_form_data.data.frm;

        console.log("form data: ", this.formData);

        this.loading = false;
      } catch (error) {
        this.error = true;
        this.errorMessage = "Submitting form data " + error.message;
        console.log("Submitting form data " + error.message);

        setTimeout(() => {
          this.error = false;
          this.errorMessage = "";
        }, 3000);
      }
    },
  },

  mounted() {
    this.getFormData();
  },
};
</script>

<style>
.bordered {
  border: 1px solid #444;
}
</style>

<template>
  <v-container>
    <v-row v-if="loading">
      <v-col>
        <h3 class="text-center">
          <v-progress-circular indeterminate class="ml-3" color="primary" />
          Getting data . . .
        </h3>
      </v-col>
    </v-row>

    <v-alert
      v-show="error"
      transition="fade-transition"
      color="error"
      type="error"
      style="z-index: 2; position: fixed; right: 15px; min-width: 300px"
    >
      {{ errorMessage }}
    </v-alert>

    <v-row v-if="!loading" justify="center">
      <v-col xs="12" xl="6">
        <v-row>
          <v-col>
            <p class="text-h4">Initial KYC Form</p>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-card
              v-for="section in formData.frm_sects"
              :key="section.id"
              flat
            >
              <v-card-title>
                {{ section.title }}
              </v-card-title>

              <v-card-text>
                <div v-for="rsp_q in section.frm_rsp_qs" :key="rsp_q.id">
                  <v-divider />
                  <v-row class="py-2">
                    <v-col cols="9" class="grey--text">
                      • {{ rsp_q.frm_q.title }}
                    </v-col>
                    <v-col cols="3" class="green--text">
                      {{ rsp_q.response }}
                    </v-col>
                  </v-row>

                  <span
                    v-for="(account, account_index) in rsp_q.frm_q.title ===
                    'Are you investing in mortgages on behalf of a corporation that is primarily in the business of lending money via mortgages (e.g., Mortgage Investment Company, Mortgage Brokerage)?'
                      ? mortgage_company_accounts
                      : rsp_q.frm_q.title ===
                        'Are you investing in mortgages with funds from a corporation you own?'
                      ? private_corporation_accounts
                      : []"
                    :key="account.id"
                  >
                    <v-divider />
                    <v-row class="py-2">
                      <v-col cols="2" class="grey--text text-right">
                        {{ account_index === 0 ? "Account List: " : "" }}
                      </v-col>
                      <v-col cols="7" class="green--text">
                        • {{ account.name }}</v-col
                      >
                    </v-row>
                  </span>

                  <span
                    v-for="(fq_1, fq_1_index) in rsp_q.frm_rsp_qs"
                    :key="fq_1.id"
                  >
                    <v-divider />
                    <v-row class="py-2">
                      <v-col cols="2"> </v-col>
                      <v-col cols="7" class="grey--text">
                        •
                        {{
                          fq_1.frm_q && fq_1.frm_q.title ? fq_1.frm_q.title : ""
                        }}
                      </v-col>
                      <v-col cols="3" class="green--text">
                        {{ fq_1.response }}
                      </v-col>
                    </v-row>

                    <span
                      v-for="(account, account_index) in fq_1.frm_q.title ===
                      'Do you invest in Mortgages with an RRSP?'
                        ? RRSP_accounts
                        : fq_1.frm_q.title ===
                          'Do you invest in mortgages with a RRIF?'
                        ? RRIF_accounts
                        : fq_1.frm_q.title ===
                          'Do you invest in mortgages with a TFSA?'
                        ? TFSA_accounts
                        : []"
                      :key="account.id"
                    >
                      <v-divider />
                      <v-row class="py-2">
                        <v-col cols="4" class="grey--text text-right">
                          {{ account_index === 0 ? "Account List: " : "" }}
                        </v-col>
                        <v-col cols="5" class="green--text">
                          • {{ account.name }}</v-col
                        >
                      </v-row>
                    </span>
                  </span>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-btn @click="$router.go(-1)"> Cancel </v-btn>
            <v-btn class="ml-5" @click="handle_print_btn">
              <v-icon class="mr-2"> mdi-printer </v-icon> Print
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import API from "@/plugins/API";

export default {
  name: "Initial_KYC_Response",

  props: ["form_response_id"],

  data: () => ({
    loading: false,
    error: false,
    errorMessage: "",

    formData: {},

    companyData: {},
  }),

  methods: {
    handle_print_btn() {
      window.print();
    },

    async getFormData() {
      try {
        this.loading = true;
        const found_form_data = await API().get(
          `api/form/read_form_response?id=${this.form_response_id}`
        );
        console.log("found_form_data: ", found_form_data);

        this.formData = found_form_data.data.frm;

        console.log("form data: ", this.formData);

        this.companyData = found_form_data.data.company;

        // console.log("company data: ", this.companyData);

        this.loading = false;
      } catch (error) {
        console.log(error);
        this.pageIsError = true;
        setTimeout(() => {
          this.pageIsError = false;
        }, 2000);
      }
    },
  },
  computed: {
    // existing accounts
    RRSP_accounts() {
      return this.companyData.accounts.filter(
        (account) => account.category === "Private" && account.type === "RRSP"
      );
    },

    TFSA_accounts() {
      return this.companyData.accounts.filter(
        (account) => account.category === "Private" && account.type === "TFSA"
      );
    },

    RRIF_accounts() {
      return this.companyData.accounts.filter(
        (account) => account.category === "Private" && account.type === "RRIF"
      );
    },

    private_corporation_accounts() {
      return this.companyData.accounts.filter(
        (account) =>
          account.category === "Private" && account.type === "Corporation"
      );
    },

    mortgage_company_accounts() {
      return this.companyData.accounts.filter(
        (account) => account.category !== "Private"
      );
    },
  },

  mounted() {
    this.getFormData();
  },
};
</script>

<style>
.bordered {
  border: 1px solid #444;
}
</style>
